import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import Product from "../../components/ProductItem"
import Inner from "../../components/Inner"
import Info from "../../components/Info"
import Divider from "../../components/Divider"

const List = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, auto));
	grid-auto-columns: 300px;
	grid-gap: 16px;
`

const Content = styled.div`
	padding: 2em 0;
`

const Products = styled.section``

const Section = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiProducts {
				edges {
					node {
						name
						shortDescription
						productCategory {
							name
						}
						price
						slug
						thumbnail {
							publicURL
						}
					}
				}
			}
		}
	`)

	const products = data.allStrapiProducts.edges

	return (
		<Products>
			<Inner>
				<Content>
					<Info title={"Products"} detail={"Our lineup products made in house by us with heart."} />
					<Divider />
					<List>
						{products.map((item, index) => {
							const { productCategory, name, price, shortDescription, slug, thumbnail } = item.node

							return (
								<Link key={index} to={slug}>
									<Product
										category={productCategory ? productCategory.name : ""}
										name={name}
										price={price}
										shortDescription={shortDescription}
										imageUrl={thumbnail.publicURL}
									/>
								</Link>
							)
						})}
					</List>
				</Content>
			</Inner>
		</Products>
	)
}

export default Section
