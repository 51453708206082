import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import { FaEyeSlash } from "react-icons/fa"

import * as Color from "../../utils/color"

const Price = styled.div`
	${tw`text-xl`}
	font-weight: bold;
	color: lightgray;
`

const Short = styled.div`
	${tw`text-base`}
	font-weight: bold;
	color: lightgray;
`

const Name = styled.div`
	${tw`text-xl tracking-wide leading-tight`}
	text-transform: capitalize;
	font-weight: bold;

	color: ${Color.primary};
`

const Category = styled.div`
	${tw`text-xs`}
	font-weight: bold;
	text-transform: uppercase;

	color: ${Color.secondary};
`

const Info = styled.div`
	display: grid;
	grid-template-areas:
		"category price"
		"name price"
		"short price";

	grid-template-columns: auto min-content;

	${Name} {
		grid-area: name;
	}

	${Short} {
		grid-area: short;
	}

	${Price} {
		grid-area: price;
		padding-top: 50%;
		justify-self: flex-end;
	}
`

const NoImage = styled.div`
	height: 300px;
	background-color: white;
	border: solid 1px lightgray;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
`

const Image = styled.img`
	width: 100%;
	border-radius: 8px;
`

const ImageContainer = styled.div`
	${tw`mb-2`}
`

const Product = styled.div``

const ProductWrapper = ({
	category = "untitled",
	name = "unknown",
	shortDescription,
	price = "0",
	imageUrl,
	imageAlt,
	...props
}) => {
	return (
		<Product>
			<ImageContainer>
				{imageUrl ? (
					<Image src={imageUrl} alt={imageAlt} />
				) : (
					<NoImage>
						<FaEyeSlash size={42} />
					</NoImage>
				)}
			</ImageContainer>
			<Info>
				<Category>{category}</Category>
				<Name>{name}</Name>
				<Short>{shortDescription}</Short>
				<Price>{"$" + price}</Price>
			</Info>
		</Product>
	)
}

export default ProductWrapper
