import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { mobile } from "../../utils/breakpoint"
import * as Color from "../../utils/color"

const Info = styled.div`
	${tw`mb-8`}
`

const Subtitle = styled.p`
	${tw`text-xs md:text-sm lg:text-sm tracking-widest`}
	color: ${Color.primary};

	&::after {
		${tw`mt-2`}

		content: "";
		display: block;
		width: 40px;
		height: 4px;
		background-color: ${Color.primary};
	}
`

const Title = styled.h2`
	${tw`text-4xl md:text-5xl mb-1`}
	color: ${Color.primary};
	font-weight: bold;
`

const Detail = styled.p`
	${tw`text-base md:text-lg`}
	color: ${Color.primary};
	width: ${props => (props.fullWidth ? "100%" : "50%")};

	@media ${mobile} {
		width: 100%;
	}
`

const InfoWrapper = ({ subtitle, title, detail, fullWidth, ...props }) => {
	return (
		<Info {...props}>
			{subtitle && <Subtitle>{subtitle}</Subtitle>}
			<Title>{title}</Title>
			<Detail fullWidth={fullWidth}>{detail}</Detail>
		</Info>
	)
}

export default styled(InfoWrapper)`
	display: flex;
	flex-direction: column;
	justify-content: center;

	${props =>
		props.center &&
		css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
		`}

	${props =>
		props.right &&
		css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			text-align: right;
		`}
`
