import React from "react"
import Products from "../sections/products"

const Wrapper = () => {
	return (
		<>
			<Products />
		</>
	)
}

export default Wrapper
