import styled from "@emotion/styled"
import { mobile, tablet, desktop } from "../../utils/breakpoint"

const Inner = styled.div`
	@media ${mobile} {
		padding: 0 3em;
	}

	@media ${tablet} {
		padding: 0 4em;
	}

	@media ${desktop} {
		padding: 0 8em;
	}
`

export default styled(Inner)``
